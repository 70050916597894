import React, { useState } from "react";
import ReactJson from "react-json-view";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import convert from "xml-js";
import "./JsonBeautifier.css";
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import Textarea from "../../../components/Textarea/Textarea";
import BackButton from "../../../components/BackButton/BackButton";
import "../json-utility.css";
import Select from "../../../components/Select/Select";

function JsonBeautifier() {
  const [inputJson, setInputJson] = useState("");
  const [formattedJson, setFormattedJson] = useState("");
  const [jsonPath, setJsonPath] = useState("");
  const [xml, setXml] = useState("");
  const [isJsonToXml, setIsJsonToXml] = useState(false);
  const [collapsed, setCollapsed] = useState({});
  const [tabSpaces, setTabSpaces] = useState(2);
  const [searchQuery, setSearchQuery] = useState("");

  const handleBack = () => {
    setInputJson("");
    setFormattedJson("");
    setJsonPath("");
    setXml("");
    setIsJsonToXml(false);
    setCollapsed({});
    setTabSpaces(2);
  };

  const handleJsonChange = (e) => {
    setInputJson(e.target.value);
    try {
      let json = JSON.parse(e.target.value);
      if (typeof json === "string") {
        json = JSON.parse(json);
      }
      setFormattedJson(JSON.stringify(json, null, tabSpaces));
      setInputJson(JSON.stringify(json, null, tabSpaces));
    } catch (error) {
      try {
        const jsonObj = eval("(" + e.target.value + ")");
        setFormattedJson(JSON.stringify(jsonObj, null, tabSpaces));
        setInputJson(JSON.stringify(jsonObj, null, tabSpaces));
      } catch (evalError) {
        toast.error("Invalid JSON or JavaScript object. Please check your input.");
        setFormattedJson("");
        setInputJson("");
      }
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const json = JSON.parse(event.target.result);
        setInputJson(JSON.stringify(json, null, tabSpaces));
        setFormattedJson(JSON.stringify(json, null, tabSpaces));
      } catch (error) {
        toast.error("Invalid JSON file. Please upload a valid JSON file.");
      }
    };
    reader.readAsText(file);
  };

  const handleValidate = () => {
    try {
      JSON.parse(inputJson);
      toast.success("Valid JSON.");
    } catch (error) {
      toast.error("Invalid JSON. Please correct the errors.");
    }
  };

  const handleFormat = () => {
    try {
      const json = JSON.parse(inputJson);
      setFormattedJson(JSON.stringify(json, null, tabSpaces));
    } catch (error) {
      toast.error("Invalid JSON. Please correct the errors.");
    }
  };

  const handleMinify = () => {
    try {
      const json = JSON.parse(inputJson);
      setFormattedJson(JSON.stringify(json));
    } catch (error) {
      toast.error("Invalid JSON. Please correct the errors.");
    }
  };

  const handleDownload = () => {
    const element = document.createElement("a");
    const file = new Blob([formattedJson], { type: "application/json" });
    element.href = URL.createObjectURL(file);
    element.download = "formatted.json";
    document.body.appendChild(element);
    element.click();
  };

  const handleCopyFormattedJson = () => {
    if (formattedJson) {
      navigator.clipboard
        .writeText(formattedJson)
        .then(() => toast.success("Formatted JSON copied to clipboard!"))
        .catch(() => toast.error("Failed to copy formatted JSON."));
    }
  };

  const handleSelect = (select) => {
    const path = [...select.namespace, select.name].join(".");
    setJsonPath(path);
  };

  const convertJsonToXml = () => {
    try {
      const json = JSON.parse(formattedJson);
      const xmlResult = convert.js2xml(json, { compact: true, spaces: 4 });
      setXml(xmlResult);
    } catch (error) {
      toast.error("Error converting JSON to XML. Please check your JSON.");
    }
  };

  const copyToClipboard = () => {
    const xmlContent = xml;
    navigator.clipboard
      .writeText(xmlContent)
      .then(() => toast.success("XML copied to clipboard!"))
      .catch(() => toast.error("Failed to copy XML."));
  };

  const handleCopyJsonPath = () => {
    if (jsonPath) {
      navigator.clipboard
        .writeText(jsonPath)
        .then(() => toast.success("JSON Path copied to clipboard!"))
        .catch(() => toast.error("Failed to copy JSON Path."));
    }
  };

  const toggleConversion = () => {
    setIsJsonToXml(!isJsonToXml);
  };

  const handleCollapse = (path) => {
    setCollapsed({ ...collapsed, [path]: !collapsed[path] });
  };

  const handleDelete = (path) => {
    let parsedJson = JSON.parse(formattedJson);
    const pathArray = path.split(".");
    let current = parsedJson;
    for (let i = 0; i < pathArray.length - 1; i++) {
      current = current[pathArray[i]];
    }
    delete current[pathArray[pathArray.length - 1]];
    const newJson = JSON.parse(JSON.stringify(parsedJson));
    setFormattedJson(JSON.stringify(newJson, null, tabSpaces));
    setInputJson(JSON.stringify(newJson, null, tabSpaces));
  };

  const handleTabSpacesChange = (e) => {
    setTabSpaces(parseInt(e.target.value));
    handleFormat();
  };

  return (
    <div className="json-utility-container json-beautifier">
      <BackButton
        onBack={inputJson || formattedJson || xml ? handleBack : null}
        title="JSON Beautifier"
      />

      <div className="main-content">
        <div className="editor-section">
          <div className="toolbar">
            <Input type="file" onChange={handleFileUpload} />
            <Button onClick={handleValidate}>Validate</Button>
          </div>
          <Textarea
            value={inputJson}
            onChange={handleJsonChange}
            placeholder="Enter JSON or JavaScript object here"
            className="Input-Textarea"
          />
        </div>

        <div className="controls-section">
          <Select
            options={[
              { value: 2, label: "2 Tab Spaces" },
              { value: 4, label: "4 Tab Spaces" },
              { value: 8, label: "8 Tab Spaces" },
            ]}
            value={tabSpaces}
            onChange={handleTabSpacesChange}
          />
          <Button onClick={handleFormat}>Format / Beautify</Button>
          <Button onClick={handleMinify}>Minify / Compact</Button>
          <Button onClick={toggleConversion}>
            {isJsonToXml ? "Switch to JSON Parse" : "Switch to XML Conversion"}
          </Button>
          {isJsonToXml ? (
            <>
              <Button onClick={convertJsonToXml}>Convert to XML</Button>
              <Button onClick={copyToClipboard}>Copy XML</Button>
            </>
          ) : (
            <>
              <Button onClick={handleCopyFormattedJson}>
                Copy Formatted JSON
              </Button>
              <Button onClick={handleCopyJsonPath}>Copy JSON Path</Button>
              <Button onClick={handleDownload}>Download</Button>
            </>
          )}
        </div>

        <div className="editor-section">
          {isJsonToXml ? (
            <Textarea
              value={xml}
              placeholder="XML will appear here"
              className="output-Textarea"
              readOnly
            />
          ) : (
            <div className="json-view-container">
              <Input
                type="text"
                value={jsonPath}
                placeholder="JSON Path"
                className="json-path-Input"
                readOnly
              />
              <Input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search JSON"
                className="json-search-Input"
              />
              <ReactJson
                src={JSON.parse(formattedJson || "{}")}
                onSelect={handleSelect}
                collapsed={collapsed}
                theme="apathy:inverted"
                displayDataTypes={false}
                filter={
                  searchQuery
                    ? (name, value) =>
                        name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        String(value)
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                    : null
                }
              />
              <div className="json-actions">
                <Button onClick={() => handleCollapse(jsonPath)}>
                  Collapse/Expand
                </Button>
                <Button onClick={() => handleDelete(jsonPath)}>Delete Node</Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default JsonBeautifier;
